import React from "react";

function PartnersContent() {
    return (
        <>
            <section class="partnerh">
                <div class="container-fluid">
                    <h2 class="title01">Our Partners</h2> 
                </div>
            </section>

            <section class="partner-page">
                <div class="container largeContainer">
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Aarvee-Associates.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Abi-Showatech-India-Ltd.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/accenture.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Adani-Enterprises.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Agilisium.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/AGS-Healthcare.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/AICL.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Alleima-India.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/AlphagrepSecurities.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Ambernath-Organics.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Anunta-Technologies.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/apaengineering.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>   
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/aptiv.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Arghyam-Foundation.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Asirvad.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ATTLogo.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/avalon.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/BakerHughes.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Bank-of-America.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/bharatpetroleum.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Bitzer.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/blue-star.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/BorgWarner.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Canara-Bank.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Castle-Ships.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Cholamandalam-MS-Risk.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Cintra.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/CME_Group.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/cams.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/concreteoem.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/credavenue-yobi.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Daimler.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ea-india.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Encora.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/excelra.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/fedbank.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/fedex.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Ford_India.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/fortis.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/FOSROC.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Future_Generali_India.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/gavs.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/GE-Power.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Geojit-Foundation.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/google.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/guavus.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/HEFA.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Herbalife.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Hexaware.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/HFCL.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Hindustan-Colas.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Hindustan-Unilever.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/HSBC.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/huawei.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/cytiva.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/hyundai.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ibm.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/icicihomefin.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/idbi.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/indegene.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/IndInfravitTrust.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/IndusTowers.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/IndusIndBank.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/inspirisys.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Intel-Technology.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/internationalseaport.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Ipsos.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ittiam.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/janashikshasanstha.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/johnson-controls.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/KotakMahindraBank.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/LTIMindtree.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/LatentView.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/LearningLinks.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/lgsoftindia.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Magna_International.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Mazgon-Docks.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/mediadotnet.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Mercedes-Benz.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Milacron.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/mistral.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/mospi.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Mphasis.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/mrftyres.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/nbcc.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/nineleaps.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/nordex.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ocpindia.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/oneplus.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/pelatro.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Petronet-LNG-limited.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/PHN-Technologies.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Pioneer.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/powerfinance.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/powergrid.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Reddington.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/rr-donnelley.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Ruhrpumpen.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/sabre.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Sandvik-Alleima.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/SBI_General_Insurance.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/schaeffler.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/theshippingcorporation.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/siemens.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/snsfoundation.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Solverminds.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/SRLDiagnostics.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/SRL Limited-Fortis.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/StGobain.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Structural-Specialities.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/SVP-Philanthropy.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/TanlaFoundation.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/tata-advanced-systems.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/tataaialife.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/tcs.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Temenos.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/ThalesDisTechnology.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/The-Great-Eastern.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/thenatureconservancy.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/wellcometrust.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/TMB.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Tower_Research_Capital.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/TransunionGlobal.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Trident.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Ultratech-Cement.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/UniBio.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Venus-Safety-Health.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/vertiv.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/Wabtec.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/wiprocares.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/WHO.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/yuken.jpg" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    {/* <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>                                
                            </div>
                        </div>
                    </div> */}

				</div>
            </section>

        </>
    )
}

export default PartnersContent;