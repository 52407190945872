import React from "react";
import './team.css'

export default function TeamContentNew() {
    return (
        <>

            <section className="page_banner_team" style={{ backgroundImage: "url(assets/images/team/61.jpg)", maxWidth: "100%", width: "100%" }}>
            {/* <div className="container-fluid">
                    <h1 className="titleT">Our Team</h1>
                </div> */}
            </section>

            <section className="teamPage">
                <div className="container largeContainer">
                    <div className="row">

                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/ashwin.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/ashwin-mahalingam-084b3b216/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Prof. Ashwin Mahalingam</a></h3>
                                <p>Dean, Alumni & Corporate Relations - Chennai</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/kaviraj.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/kaviraj-nair-686b89/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Kaviraj Nair</a></h3>
                                <p>CEO, Office of Institutional Advancement - Chennai</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/Vasudha.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/vasudha-narasimhan-54893223/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Vasudha Narasimhan</a></h3>
                                <p>Vice President, CSR - Chennai</p>
                            </div>
                        </div>                        

                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/vibha.png" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/vibha-jain-b37523b9/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Vibha Jain</a></h3>
                                <p>Assistant Vice President, CSR - Mumbai</p>
                            </div>
                        </div>

                        {/* For GURBIRENDER SINGH GILL */}
                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/gurbirender.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/gurbirender-gill-185a146/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Gurbirender Singh Gill</a></h3>
                                <p>Vice President, CSR - Delhi NCR</p>
                            </div>
                        </div>

                        {/* For Ankur Nagaria */}
                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/ankur.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/ankur-nagaria-003679aa/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Ankur Nagaria</a></h3>
                                <p>General Manager, CSR - Bangalore</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/Rajesh.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/sivanesanrajesh/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">S Rajesh</a></h3>
                                <p>GM - Operations, Alumni & Corporate Relations - Chennai</p>
                            </div>
                        </div>

                        {/* For Sunitha Anup */}
                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/sunitha.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/sunitha-anupkumar-06b51544/?trk=universal-search-cluster" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Sunitha Anupkumar</a></h3>
                                <p>Chief Manager - Marketing - Chennai</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}