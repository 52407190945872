import React from 'react'
import './SocialImpact.css';

function SocialImpactContent() {
  return (
    <div className='socialimpact'>
      <h2 className='socialheading'>Social Impact – CSR</h2>
      <a href='https://acr.iitm.ac.in/wp-content/uploads/2024/10/The-Social-Impact-Club-Chennai-CSR-Cafe.pdf' target='_blank' rel="noreferrer">THE SOCIAL IMPACT CLUB – Chennai</a><br/>
      <a href='https://acr.iitm.ac.in/wp-content/uploads/2024/10/The-Social-Impact-Club-Bangalore-CSR-Cafe-Report.pdf' target='_blank' rel="noreferrer">THE SOCIAL IMPACT CLUB – Bangalore</a><br/>
      <a href='https://acr.iitm.ac.in/wp-content/uploads/2024/10/The-Social-Impact-Club-Mumbai-CSR-Cafe.pdf' target='_blank' rel="noreferrer">THE SOCIAL IMPACT CLUB – Mumbai</a><br/>
      <a href='https://acr.iitm.ac.in/wp-content/uploads/2024/10/The-Social-Impact-Club-Delhi-CSR-Cafe.pdf' target='_blank' rel="noreferrer">THE SOCIAL IMPACT CLUB – Delhi</a>
    </div>
  )
}

export default SocialImpactContent
