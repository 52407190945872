import React from 'react'
import Preloader from '../Component/Preloader'
import PartnersContent from '../Content/PartnersContent'
import Header from '../Component/Header'
import Footer from '../Component/Footer'

function NewPartners() {
  return (
    <>
    <Preloader/>
    <Header/>
    <PartnersContent/>
    <Footer/>      
    </>
  )
}

export default NewPartners
