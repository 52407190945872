import React from 'react'
import Preloader from "../Component/Preloader";
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import SocialImpactContent from '../Content/SocialImpactContent';

function SocialImpact() {
  return (
    <>
    <Preloader/>
      <Header />
      <SocialImpactContent />
      <Footer />
    </>
  )
}

export default SocialImpact
