import React from 'react'
import Preloader from '../Component/Preloader'
import Header from '../Component/Header'
import NewsletterContent from '../Content/NewsletterContent'
import Footer from '../Component/Footer'

function Newsletter() {
  return (
    <>
    <Preloader/>
    <Header/>
    <NewsletterContent/>
    <Footer/>
    </>
  )
}

export default Newsletter
