import React from "react";

function Footer() {
    const currentYear = new Date().getFullYear(); // Get the current year

    return (
        <>
            <footer className="footer_01" style={{ "paddingTop": "0px" }}>
                <div className="container largeContainer">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="Copyright">
                                <span>Sociity</span> - Copyright {currentYear}. Developed By- <a href="#0"> ACR</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
